import Carousel from 'react-bootstrap/Carousel';
import image1 from '../1.jpg'
import image2 from '../2.jpg'
import image3 from '../3.jpg'

function CarouselMenu() {
  return (
    
    <Carousel variant="dark" className="m-4">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image1}
          height="300"
          alt="First slide"
        />
        <Carousel.Caption>
          <h5> </h5>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image2}
          height="300"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5> </h5>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image3}
          height="300"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5> </h5>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    
  );
}

export default CarouselMenu;