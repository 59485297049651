import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap'


function Header() {
  return (
    <header>
      <Navbar className="navbar navbar-expand-lg navbar-light bg-light">
  <Container>
  <LinkContainer to='/'>
        <Navbar.Brand>GeoPolRisk.com</Navbar.Brand>
        </LinkContainer>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          <LinkContainer to='/products'>
            <Nav.Link><i className="fa-solid fa-book"></i> About products</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/authors'>
            <Nav.Link><i className="fa-solid fa-users"></i>Authors </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
  </Container>
</Navbar>


    </header>
  )
}

export default Header